export default {
  type: 'form',
  url: '/config/farmLoginRegist/getUnique',
  edit: '/config/farmLoginRegist/update',
  data: [
    {
      name: '主标题',
      type: 'input',
      key: 'mainTitle'
    },
    {
      name: '副标题',
      type: 'input',
      key: 'subTitle'
    },
    {
      name: '短信验证时间',
      type: 'input',
      desc: '建议:60s',
      key: 'verifySeconds',
      props: {
        addonAfter: 'S'
      }
    },
    {
      name: 'Banner图',
      type: 'iconUpload',
      key: 'a1',
      typeData: [
        {
          key: 'image2',
          desc: '@2x   xhdpi <br/>上传尺寸750*360px'
        },
        {
          key: 'image3',
          desc: '@3x   xxhdpi<br/>上传尺寸1125*540px'
        }
      ]
    }
  ]
}
